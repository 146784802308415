import { css } from 'astroturf';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import CTA from '../../components/CTA';
import DocHeader from '../../components/DocHeader';
import DocsListing from '../../components/DocsListing';
import SEO from '../../components/SEO';
import ShortcutDisplay from '../../components/ShortcutDisplay';
import DocSupport from '../../components/docs/support';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .docContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 40rem) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .menu {
    position: sticky;
    top: 70px;
    padding-top: 10px;
    z-index: 9;

    & > ul {
      display: none;
    }

    & button {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0 0.5rem;
      &:hocus {
        background: #fff;
        color: #107db5;
      }
    }
    @media (min-width: 40rem) {
      position: relative;
      top: 0;
      overflow-y: visible;
      max-height: initial;
      flex: 1;
      display: block;

      & > button {
        display: none;
      }
      & > ul {
        display: block;
      }
    }
  }

  .open {
    & > ul {
      background: #fff;
      display: block;
      overflow: auto;
      max-height: calc(100vh - 118px);
    }
  }

  .content {
    flex: 2;

    & > *:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (min-width: 40rem) {
      padding-left: 1.5rem;
    }
  }
  .dirNav {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    & svg {
      vertical-align: text-bottom;
      transform: translateY(-2px);
    }
  }
`;

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  render() {
    const menuClasses = [styles.menu, this.state.menuShown ? styles.open : undefined].filter((x) => x).join(' ');

    return (
      <Layout>
        <SEO title="UI Overview" keywords={['']} pathname={this.props.location.pathname} />
        <DocHeader />
        <div className="widePage">
          <div className={styles.docContainer}>
            <div className={menuClasses}>
              <button type="button" onClick={this.showMenu}>
                {this.state.menuShown ? 'Hide menu' : 'Show menu'}
              </button>
              <DocsListing />
            </div>
            <section className={styles.content}>
              <h1 id="polypane-ui-overview">Polypane UI overview</h1>

              <div className="childPadding">
                <p>
                  Parts of Polypane will be still familiar if you come from a different browser: There's an address bar,
                  forward and backward buttons and a reload button.
                </p>
                <p>
                  However, other parts of the UI might be less familiar. With this overview of the Polypane UI you
                  should be able to find most of the options.
                </p>
                <p>Polypane can be split into roughly three areas:</p>
                <ul>
                  <li>
                    <strong>The header</strong>, with the address bar and navigation UI.
                  </li>
                  <li>
                    <strong>The workspace</strong>, where your panes are.
                  </li>
                  <li>
                    <strong>The panel</strong>, where the devtools are.
                  </li>
                </ul>

                <h3 id="the-header">The header</h3>
                <p>
                  The header is the similar to other browsers: there's a tab bar, an address bar, back and forward
                  buttons and a reload button.
                </p>

                <StaticImage
                  src="../../../assets/images/docs/ui-header.png"
                  alt=""
                  objectFit="contain"
                  placeholder="none"
                  className="imgshadow"
                  imgStyle={{ transition: 'none' }}
                />

                <p>
                  The navigational buttons will be clickable depending on if there is a forward or backward history, and
                  right clicking will show you a list of previously visited pages.
                </p>
                <h4 id="reload">Reload</h4>
                <p>
                  Clicking the Reload icon will reload the page, but if you right click it you get a few more options:
                </p>
                <ul>
                  <li>
                    <Link to="/docs/reload-css/">Reload the CSS</Link>
                  </li>
                  <li>Hard reload</li>
                  <li>Clear cookies and reload</li>
                </ul>
                <p>
                  You can also find quick options to scroll to the top or bottom of the page and access the <Link to="/docs/live-auto-reloading/">Live and Auto reloading</Link>{' '}
                  configuration from here, which lets you automate reloading pages based on changes you make in your
                  code editor.
                </p>
                <p>If you click the reload button again while the page is loading, loading will be stopped.</p>
                <p>
                  Tabs can be re-arranged by drag and drop, you can click the `+` button to add a new tab (or use{' '}
                  <kbd>cmd/ctrl + t</kbd>). Right-clicking the `+` button lets you pick a new{' '}
                  <Link to="/docs/layouts/">layout</Link> for the new tab.
                </p>
                <h4 id="the-button-bar">The buttons in the header</h4>

                <p>
                  To the right of the address bar is a set of icons that are unique to Polypane. Different layouts can
                  show different buttons depending on whether or not they're available for that layout.
                </p>

                <p>From left to right (in horizontal and vertical layouts):</p>
                <ul>
                  <li>
                    <Link to="/docs/layouts/">Change layout</Link>. Click it to choose between horizontal, vertical,
                    focus and full mode.
                  </li>

                  <li>
                    <Link to="/docs/session-management/">Session management</Link>. Switch to a different session.
                  </li>

                  <li>
                    <Link to="/docs/color-picker/">Color Picker</Link>. Pick colors from anywhere on the screen. Right
                    click for the color picker UI.
                  </li>
                  <li>
                    <Link to="/docs/synced-interactions/">Scroll Sync</Link>. Toggle synchronized scrolling on and off.
                    When off, a small `x` is visible on the icon. Right click to manage other synchronisation features
                  </li>
                  <li>
                    <Link to="/docs/breakpoints/">Breakpoints</Link>. Clicking this button will creates panes for all
                    breakpoins in the site CSS. Right click to find a list of all the breakpoints, and the 'simplified'
                    breakpoints option.
                  </li>
                  <li>
                    <Link to="/docs/making-screenshots/">Screenshot</Link>. Makes a overview screenshot of all panes
                    together.
                  </li>
                  <li>
                    <Link to="/docs/elements-panel/">Inspect element</Link>. Click to inspect elements in all panes.
                    Right click to inspect with pane-specific devtools. (this setting is remembered.)
                  </li>
                  <li>
                    <Link to="/docs/developer-tools/">Open devtools</Link>. Click to toggle the developer tools. Right
                    click to open the <Link to="/docs/browser-extensions/">Browser extensions manager</Link>.
                  </li>
                  <li>
                    <Link to="/docs/intro-to-panel/">Open Panel</Link>. Click to open the side panel, where you can find
                    Meta information, social media previews, live CSS, handoff tools and an overview of your saved
                    workspaces.
                  </li>
                  <li>
                    <Link to="/docs/global-zoom/">Global zoom</Link> lets you zoom all your panes at once. The
                    percentage can be clicked and manually changed as well. By double clicking the percentage you can
                    zoom to 100% or zoom to fit.
                  </li>
                  <li>
                    <Link to="/docs/browser-extensions/">Browser Extensions</Link> Click to toggle showing your browser
                    extensions or right-click to open the extensions manager.
                  </li>
                  <li>
                    <strong>App settings</strong> contains various global options to turn on and off, like web vitals
                    and console messages, as well as performance options. You can also open this with the shortcut{' '}
                    <ShortcutDisplay letter="," cmdKey />.
                  </li>
                </ul>
                <p>In Full mode the Pane-specific options are also added to the header. UI.</p>

                <h3 id="the-workspace">The Workspace</h3>
                <p>
                  The workspace is where all your panes are shown. To learn more about the different layouts, read{' '}
                  <Link to="/docs/layouts/">the layout section</Link> of our documentation.
                </p>

                <StaticImage
                  src="../../../assets/images/docs/ui-workspace.png"
                  alt=""
                  objectFit="contain"
                  placeholder="none"
                  className="imgshadow"
                  imgStyle={{ transition: 'none' }}
                />

                <p>
                  To learn more about panes and their UI, read the{' '}
                  <Link to="/docs/intro-to-panes/">Intro to panes</Link>.
                </p>
                <h3 id="the-panel">The Panel</h3>
                <p>
                  The panel is where you find some of the more advanced tools that Polypane offers, like the unified
                  console, the combined element inspector and the meta information panel.
                </p>

                <StaticImage
                  src="../../../assets/images/docs/ui-panel.png"
                  alt=""
                  objectFit="contain"
                  placeholder="none"
                  className="imgshadow"
                  imgStyle={{ transition: 'none' }}
                />

                <p>
                  The panel can be opened by clicking the icon in the header or by pressing <kbd>Shift ctrl/cmd p</kbd>{' '}
                  and can be docked to the right, left or bottom of the window, or in its own window.
                </p>
                <p>
                  For a full overview, read the <Link to="/docs/intro-to-panel/">intro to panel</Link>
                </p>
              </div>
              <DocSupport />
            </section>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query UIOverviewIndexQuery {
    overview: file(relativePath: { eq: "images/docs/full-overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 582, quality: 100, layout: CONSTRAINED)
      }
    }
    buttons: file(relativePath: { eq: "images/docs/buttons.png" }) {
      childImageSharp {
        gatsbyImageData(width: 582, quality: 100, layout: CONSTRAINED)
      }
    }
    paneHeader: file(relativePath: { eq: "images/docs/paneheader.png" }) {
      childImageSharp {
        gatsbyImageData(width: 582, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`;
